import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from "rxjs/operators";
import { Globals } from './globals';
import { AuthService } from './services/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class HttpInterceptorClassService implements HttpInterceptor {
  constructor(private router: Router, private globals: Globals, private AuthService: AuthService, private CookieService: CookieService) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 

    if (req.url.indexOf('https://api.ipify.org') === 0 || req.url==="https://my.amsn.org/xweb/secure/netforumxml.asmx") { 
      req = req.clone({
        url: req.url.replace("http://", "https://")
      });
      return next.handle(req); // do nothing
    } else {

      req = req.clone({
        setHeaders: {
          Authorization: `${window.atob(this.CookieService.get('token_type')) + ' ' + this.CookieService.get('access_token')}`,
          Currentrole: (this.globals.institute_details && this.globals.institute_details.role_id) ? this.globals.institute_details.role_id : "null",
          Instituteid: (this.globals.institute_details && this.globals.institute_details.institute != null && this.globals.institute_details.institute != "") ? this.globals.institute_details.institute.id : "null",
          Product: '31c5fda4-3ec4-11eb-99e5-cdf45df6b9bb',
          Token: '9w1uvdA4YlcvlvTXjki7',
          
        }
      });
    }

    
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event.body.error_code == 2008) {
        } else {
          return next.handle(req);
        }
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {        
        if ((err.status === 401 && err.error.message == "Unauthenticated.")) {
          this.globals.isLoading = false;
          this.globals.authData = '';
          this.CookieService.delete('access_token','/', this.globals.CookieDomainUrl);
          this.CookieService.delete('amsn_token','/', this.globals.CookieDomainUrl);
          this.CookieService.delete('expires_at','/', this.globals.CookieDomainUrl);
          this.CookieService.delete('institute_details','/', this.globals.CookieDomainUrl);
          this.CookieService.delete('token_type','/', this.globals.CookieDomainUrl);
          this.CookieService.delete('user_token','/', this.globals.CookieDomainUrl);
          localStorage.removeItem('auth_user');
          this.CookieService.delete('auth_user','/', this.globals.CookieDomainUrl);
          this.globals.isLoading = false;
          return window.location.href = this.globals.amsnLogoutUrl;
        } else if(err.status === 429){
          this.globals.isLoading = false;
          this.globals.sweetAlert('error','Oops...','Too many request or redirection.');
          window.location.reload();
          return next.handle(req);
        }
         else if(err.status === 403){
          this.globals.isLoading = false;
          this.globals.sweetAlert('error','Disconnected!','The Organization or User has been disconnected.');
          setTimeout(() => {            
            this.router.navigate(['/permission-not-found']);
          }, 2000);
        } 
        else{
          return next.handle(req);
        }
      }
    }));

  }
}
