<div class="auth_dashboard_block inner_content_block pt-0 pb-0">
  <div class="bg_block">
      <div class="white_block">
          <app-header *ngIf="globals.authData!=null"></app-header>
          <div class="container-fluid">
              <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div class="auth_main_block">
                          <div class="">
                            <div class="notfound_block text-start">
                              <div class="row">
                                <div class="col-12 col-xl-8 col-lg-8 col-md-12">
                                  <div class="not_found_title_block">
                                    <img src="{{globals.cndURL}}assets/images/amsn-404.jpg" alt="" class="img-fluid" />
                                  <h1 class="float-start">Page Not Found</h1>
                                  <div class="clearfix"></div>
                                </div>
                                <div class="not_found_content">
                                  <p class="padding_15">We're sorry, but the page you're looking for couldn't be found. The information may have been moved or deleted.</p>
                                  <p class="padding_15">You may also return to the <a routerLink="/dashboard">Dashboard</a>.</p>
                                </div>
                                </div>
                                <div class="col-12 col-xl-4 col-lg-4 col-md-12">
                                  <div class="contact_block">
                                    <p>Please contact us with any questions you may have about IFMA.</p>
                                    <a href="#" target="_blank" class="all_btn themewhite_btn">Contact Us</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <app-footer *ngIf="globals.authData!=null"></app-footer>
</div>