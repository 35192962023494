<div class="auth_dashboard_block">
    <div class="bg_block">
        <div class="white_block">
            <app-header *ngIf="globals.authData!=null"></app-header>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div class="right_links">
                            <button *ngIf="administrator_present" class="link_button" (click)="goToDirectAdmin()"><img
                                    src="{{globals.cndURL}}assets/images/administrator.png" alt=""
                                    class="img-fluid" />Adminstration</button>
                            <button *ngIf="instituteList.length==0" class="link_button"><a
                                    href="{{globals.connectorUrl}}/organization-registration"><img
                                        src="{{globals.cndURL}}assets/images/org.png" alt=""
                                        class="img-fluid" />Register New organization</a></button>
                            <button *ngIf="role_present==1 && productList.length>0" class="link_button"
                                (click)="changeRole(2)"><img src="{{globals.cndURL}}assets/images/org.png" alt=""
                                    class="img-fluid" />Access as organization</button>
                            <button *ngIf="role_present==2 && productList.length>0" class="link_button"
                                (click)="changeRole(1)"><img src="{{globals.cndURL}}assets/images/indi.png" alt=""
                                    class="img-fluid" />Access as Individual</button>
                        </div>
                        <div class="auth_main_block">
                            <div class="inner_valign">
                                <div class="individual_block" *ngIf="role_present==1 && productList.length>0">
                                    <h1>Your Products</h1>
                                    <p class="padding_15">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.<br>Lorem Ipsum is simply dummy text.</p>
                                    <div class="row justify-content-center ms-1 me-1">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 d-flex"
                                            *ngIf="covid_present">
                                            <div class="product_block">
                                                <h5>COVID</h5>
                                                <div class="img_block"><img
                                                        src="{{globals.cndURL}}assets/images/covid.png" alt=""
                                                        class="img-fluid" /></div>
                                                <p class="content_block">Lorem Ipsum is simply dummy text of the
                                                    printing and typesetting industry. Lorem Ipsum is simply dummy text.
                                                </p>
                                                <div class="button_block"><button (click)="viewProduct(2,5,null)"
                                                        class="buy_link">View</button></div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 d-flex"
                                            *ngIf="dcm_indi_present">
                                            <div class="product_block">
                                                <h5>Digital Competency Framework</h5>
                                                <div class="img_block"><img
                                                        src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                                                        class="img-fluid" /></div>
                                                <p class="content_block">Lorem Ipsum is simply dummy text of the
                                                    printing and typesetting industry. Lorem Ipsum is simply dummy text.
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting
                                                    industry. Lorem Ipsum is simply dummy text.</p>
                                                <div class="button_block"><button (click)="viewProduct(3,5,null)"
                                                        class="buy_link">View</button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="organization_block" *ngIf="role_present==2 && productList.length>0">
                                    <h1>Your organizations</h1>
                                    <p class="padding_15">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.<br>Lorem Ipsum is simply dummy text.</p>
                                    <div class="row justify-content-center ms-1 me-1">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 d-flex">
                                            <div class="institute_block register_new_block">
                                                <div class="content_block">
                                                    <p>Register your own organization and become a Primary Contact </p>
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="button_block">
                                                    <a href="{{globals.connectorUrl}}/organization-registration"
                                                        class="buy_link">Register
                                                        Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngFor="let insti of instituteList; let i=index;">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 d-flex">
                                                <div class="institute_block">
                                                    <div class="content_block">
                                                        <h5>{{insti.institute_name}}</h5>
                                                        <div *ngIf="insti.provider_id" class="id_box">
                                                            {{insti.provider_id}}</div>
                                                        <p><i class="fa fa-map-marker"></i>
                                                            <span>{{insti.address1}}<br>{{insti.city}},

                                                                {{insti.states.state_abbreviation}}
                                                                {{insti.zipcode}}</span>
                                                            <!-- <i class="fa fa-user"></i>
                                                            <span> Role: Primary Contact</span> -->
                                                        </p>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                    <div class="product_list_block" kendoTooltip>
                                                        <div class="float-start" *ngIf="insti.admin_present">
                                                            <button (click)="goToAdmin(insti)" title="Adminstrator"><img
                                                                    src="{{globals.cndURL}}assets/images/administrator.png"
                                                                    alt="" class="img-fluid" /></button>
                                                        </div>
                                                        <div class="float-end"
                                                            *ngFor="let product of insti.product_list; let j=index;">
                                                            <button (click)="viewProduct(1,product.role_value,insti.id)"
                                                                title="Connector" *ngIf="product.product.value==1"><img
                                                                    src="{{globals.cndURL}}assets/images/connector.png"
                                                                    alt="" class="img-fluid" /></button>
                                                            <button (click)="viewProduct(3,product.role_value,insti.id)"
                                                                *ngIf="product.product.value==3"
                                                                title="Digital Competency Framework"><img
                                                                    src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                                                                    class="img-fluid" /></button>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <!-- <ng-container
                                    *ngIf="(!covid_present && role_present==1) || ((!dcm_indi_present && role_present==1) || (!dcm_present && role_present==2)) || (!connector_present && role_present==2)">
                                    <hr *ngIf="productList.length>0">
                                    <div class="clearfix"></div>
                                    <div class="interested_block">
                                        <h4 *ngIf="productList.length>0">You might be interested in</h4>
                                        <h1 *ngIf="productList.length==0">Buy a Product as an Individual</h1>
                                        <p class="padding_15">Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry.<br>Lorem Ipsum is simply dummy text.</p>
                                        <div class="row justify-content-center ms-1 me-1">

                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 d-flex"
                                                *ngIf="!covid_present && role_present==1">
                                                <div class="product_block">
                                                    <h5>COVID</h5>
                                                    <div class="img_block"><img
                                                            src="{{globals.cndURL}}assets/images/covid.png" alt=""
                                                            class="img-fluid" /></div>
                                                    <p class="content_block">Lorem Ipsum is simply dummy text of the
                                                        printing and typesetting industry. Lorem Ipsum is simply dummy
                                                        text.
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                                        industry. Lorem Ipsum is simply dummy text.</p>
                                                    <div class="button_block"><button class="buy_link">Buy Now</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 d-flex"
                                                *ngIf="(!dcm_indi_present && role_present==1) || (!dcm_present && role_present==2)">
                                                <div class="product_block">
                                                    <h5>Digital Competency Framework</h5>
                                                    <div class="img_block"><img
                                                            src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                                                            class="img-fluid" /></div>
                                                    <p class="content_block">Lorem Ipsum is simply dummy text of the
                                                        printing and typesetting industry. Lorem Ipsum is simply dummy
                                                        text.
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                                        industry. Lorem Ipsum is simply dummy text.</p>
                                                    <div class="button_block"><button class="buy_link"><a
                                                                href="https://www.amsn.org/iclp" target="_blank">Buy
                                                                Now</a></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 d-flex"
                                                *ngIf="!connector_present && role_present==2">
                                                <div class="product_block">
                                                    <h5>Connector</h5>
                                                    <div class="img_block"><img
                                                            src="{{globals.cndURL}}assets/images/connector.png" alt=""
                                                            class="img-fluid" /></div>
                                                    <p class="content_block">Lorem Ipsum is simply dummy text of the
                                                        printing and typesetting industry. Lorem Ipsum is simply dummy
                                                        text.
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                                        industry. Lorem Ipsum is simply dummy text.</p>
                                                    <div class="button_block"><button class="buy_link">Buy Now</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </ng-container> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer *ngIf="globals.authData!=null"></app-footer>
</div>