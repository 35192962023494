<app-header></app-header>

<div class="loginregister_block">
    <div class="white_block">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-5 col-lg-6 col-md-8">
                    <div class="full_block">
                        <form class="k-form" [formGroup]="loginForm">
                            <div class="form_block">
                                <div class="text-center">
                                    <a href="https://theopeneyes.com/" target="_blank" class="logo_oeti"><img src="{{globals.cndURL}}assets/images/oeti.png" alt=""
                                        class="img-fluid" /></a>
                                    <h3>Sign In</h3>
                                </div>
                                <kendo-formfield>
                                    <label><span>*</span> Email Address</label>
                                    <input formControlName="email" type="email" #email maxlength="100" kendoTextBox class="form-control" />
                                    <kendo-formerror *ngIf="loginForm.controls.email.errors?.required">
                                        Please enter your email.
                                    </kendo-formerror>
                                    <kendo-formerror *ngIf="loginForm.controls.email.errors?.pattern">
                                        Please enter your valid email.
                                    </kendo-formerror>
                                </kendo-formfield>

                                <kendo-formfield>
                                    <label><span>*</span> Password</label>
                                    <input formControlName="password" #password maxlength="50" type="password" kendoTextBox id="password" class="form-control" />
                                    <i class="fa fa-fw fa-eye toggle-password" id="password-show"></i>
                                    <kendo-formerror *ngIf="loginForm.controls.password.errors?.required">
                                        Please enter your password.
                                    </kendo-formerror>
                                    <kendo-formerror *ngIf="loginForm.controls.password.errors?.pattern">
                                        Use 8 or more characters with a mix of letters, numbers & symbols(! &#64; # $ & * _ - ).
                                    </kendo-formerror>
                                </kendo-formfield>

                                <div class="text-end"><a href="https://my.amsn.org/eweb/DynamicPage.aspx?WebCode=forgotpassword&Site=AMSN" class="mb-2" target="_blank">Forgot Password?</a></div>
                            </div>
                            <button kendoButton (click)="login()" class="all_btn theme_btn margin_btn float-start">Sign In</button>
                            <p class="float-end account_margin">Don't have an account? <a routerLink="/register">Register Now</a>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>