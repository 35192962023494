import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Globals } from '../globals';
import { CookieService } from 'ngx-cookie-service';
declare var $,Swal: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  instituteList:any = [];
  individual=null;
  role = 0;
  individual_present = false;
  amsn_present = false;
  oess_present = false;
  institute_count = 0;
  selectForm: UntypedFormGroup;
  UserRoleList:any = [];
  product:string = null;
  productList:any = [];
  disableBtn:boolean = true;
  product_value = 0;
  institute_value = null;
  institute_details=null;
  last_role = null;
  last_product = null;
  displayProduct:boolean = false;

  administrator_present:boolean = false;
  admin_product_id;
  admin_product_value;
  admin_institute_details = null;
  connector_present:boolean = false;
  covid_present:boolean = false;
  dcm_present:boolean = false;
  dcm_indi_present:boolean = false;

  role_present = 1;

  constructor(public globals: Globals,private AuthService: AuthService, private router: Router, 
    private fb: UntypedFormBuilder, private route: ActivatedRoute, private CookieService: CookieService) { }

  ngOnInit(): void { 
    // debugger
    this.globals.isLoading = true;

    this.product = (this.route.snapshot.paramMap.get('product')) ? this.route.snapshot.paramMap.get('product') : null;
    this.selectForm = this.fb.group({
      role: ['', [Validators.required]]
    });

    let postdata = {'user_id':this.globals.authData.id};
    this.AuthService.getUserRoleList(postdata)
    .then((data) => {
      let productList = data['data'];
      let institute_array = [];
      let connector_present = false; let covid_present = false; let dcm_present = false; let dcm_indi_present = false;
      let administrator_present = false; let admin_product_id = null; let admin_product_value = null; let admin_institute_details = null;
      let admin_covid_present = false; let admin_dcm_present = false; 
      let instituteList = [];
      this.productList = productList.filter((value) => {
        if(value.role_value==1 || value.role_value==2){           
          value.product_list.forEach(function (item) {
            if(item.product.value==2 || item.product.value==3){
              administrator_present = true;
            } 
          });
        } else if(value.role_value==5){
          value.product_list.forEach(function (item) {
            if(item.product.value==2){
              covid_present = true;
            } else if(item.product.value==3){
              dcm_indi_present = true;
            }
          });
        } else if(value.role_value==3 || value.role_value==4 || value.role_value==6){
          let admin_present = false;
          value.product_list.forEach(function (item) {
            if((item.role_value==3 || item.role_value==6) && item.product.value!=1){
              admin_present = true;
            }
            if(item.product.value==1){
              connector_present = true;
            } else if(item.product.value==3){
              dcm_present = true;
            }
          });
          if($.inArray(value.institute.id, institute_array) !== -1){
            var index = instituteList.findIndex(val => val.id === value.institute.id);
            instituteList[index].admin_present = admin_present ? admin_present : instituteList[index].admin_present;
            $.merge(instituteList[index].product_list, value.product_list);     
          } else {
            let data = value.institute;
            data.product_list = value.product_list;
            data.admin_present = admin_present;
            institute_array.push(value.institute.id);
            instituteList.push(data);
          }
          
        }
        return value;
      });

      // let ProductPermission = {'covid': admin_covid_present, 'dcm': admin_dcm_present};
      // this.CookieService.set('product_permission', window.btoa(JSON.stringify(ProductPermission)), 365, '/', this.globals.CookieDomainUrl);
      this.connector_present = connector_present;
      this.covid_present = covid_present;
      this.dcm_indi_present = dcm_indi_present;
      this.dcm_present = dcm_present;
      this.administrator_present = administrator_present;
      // this.admin_product_id = admin_product_id;
      // this.admin_product_value = admin_product_value;
      // this.admin_institute_details= admin_institute_details;
      this.instituteList = instituteList;      
      this.globals.isLoading = false;
    }, (error) => {
      this.globals.isLoading = false;
      this.globals.errorSweetAlert();
    });
  }

  changeRole(value){
    this.role_present = value;
  }

  goToDirectAdmin(){
    this.CookieService.delete('institute_details','/', this.globals.CookieDomainUrl);
    this.CookieService.delete('current_product','/', this.globals.CookieDomainUrl);
    this.CookieService.delete('current_product_id','/', this.globals.CookieDomainUrl);
    window.location.href = this.globals.adminUrl;
  }

  goToAdmin(insti){
    let institute_details;
    let role_value = null; let product_value = null; let product_id = null;
    insti.product_list.filter(function (item) {
      if(item.product.value==2 || item.product.value==3){
        role_value = role_value==null ? item.role_value : role_value;
        product_value = product_value==null ? item.product.value : product_value;
        product_id = product_id==null ? item.product.id : product_id;
      } 
    });
    institute_details = this.productList.filter((value) => {
      if(value.role_value==1 || value.role_value==2 || value.role_value==5){
        if(value.role_value==role_value){
          return value;
        }
      } else if(value.role_value==3 || value.role_value==4 || value.role_value==6){
        if(value.role_value==role_value && value.institute.id==insti.id){
          return value;
        } 
      }     
    });

    let institute_details_new:any={};
    institute_details_new.institute = institute_details[0].institute;
    institute_details_new.role_id = institute_details[0].role_id;
    institute_details_new.role_name = institute_details[0].role_name;
    institute_details_new.role_value = institute_details[0].role_value;
    institute_details_new.total_users = institute_details[0].total_users;
    institute_details_new.total_divisions = institute_details[0].total_divisions;
    institute_details_new.total_units = institute_details[0].total_units;

    this.CookieService.set('institute_details', window.btoa(JSON.stringify(institute_details_new)), 365, '/', this.globals.CookieDomainUrl);
    this.globals.institute_details = institute_details_new;
    this.CookieService.set('current_product', window.btoa(JSON.stringify(product_value)), 365, '/', this.globals.CookieDomainUrl);
    this.globals.current_product = product_value;
    this.CookieService.set('current_product_id', window.btoa(product_id), 365, '/', this.globals.CookieDomainUrl);
    window.location.href = this.globals.adminUrl;
  }

  viewProduct(product_value,role_value,institute_id){
    //  debugger
    let institute_details;
    institute_details = this.productList.filter((value) => {
      if(role_value==1 || role_value==2 || role_value==5){
        if(value.role_value==role_value){
          return value;
        }
      } else if(role_value==3 || role_value==4 || role_value==6){
        if(value.role_value==role_value && value.institute.id==institute_id){
          return value;
        }
      }     
    });
    let institute_details_new:any={};
    institute_details_new.institute = institute_details[0].institute;
    institute_details_new.role_id = institute_details[0].role_id;
    institute_details_new.role_name = institute_details[0].role_name;
    institute_details_new.role_value = institute_details[0].role_value;
    institute_details_new.total_users = institute_details[0].total_users;
    institute_details_new.total_divisions = institute_details[0].total_divisions;
    institute_details_new.total_units = institute_details[0].total_units;

    this.CookieService.set('institute_details', window.btoa(JSON.stringify(institute_details_new)), 365, '/', this.globals.CookieDomainUrl);
    this.globals.institute_details = institute_details_new;
    this.CookieService.set('current_product', window.btoa(JSON.stringify(product_value)), 365, '/', this.globals.CookieDomainUrl);
    this.globals.current_product = product_value;

    if(product_value==1){
      this.CookieService.set('current_product_id', window.btoa('0eb3cdaf-3ec4-11eb-99e5-cdf45df6b9bb'), 365, '/', this.globals.CookieDomainUrl);
      window.location.href = this.globals.connectorUrl;
    } else if(product_value==2){
      this.CookieService.set('current_product_id', window.btoa('256c4fea-3ec4-11eb-99e5-cdf45df6b9bb'), 365, '/', this.globals.CookieDomainUrl);
      window.location.href = this.globals.covidUrl;
    } else if(product_value==3){
      this.CookieService.set('current_product_id', window.btoa('31c5fda4-3ec4-11eb-99e5-cdf45df6b9bb'), 365, '/', this.globals.CookieDomainUrl);
      window.location.href = this.globals.dcmUrl;
    } 
  }

}
