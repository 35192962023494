<div class="inner_content_block assessment_main_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">

                <div class="title_block">
                    <h1 class="float-start">IFMA's self-assessment</h1>
                    <button class="all_btn themeoption_btn float-end" (click)="backToSubscription()"
                        kendoTooltip>Back</button>

                        <button class="all_btn theme_btn float-end help_btn" *ngIf="is_purchased <= 0" (click)="backToMain()">
                            Purchase Subscription</button>
                    <button id="help_btn" class="all_btn theme_btn float-end help_btn" (click)="open()">
                        Rating Guide <i class="fa fa-info-circle ms-2"></i>
                    </button>

                    <div class="clearfix"></div>

                    <p class="my-3">IFMA's self-assessment considers your skills and creates a blueprint for your FM
                        training. This
                        exercise will help you
                        identify your strengths and weaknesses across the FM knowledge base. After answering the
                        questions, you'll have a clear
                        understanding of your specific knowledge gaps.</p>
                    <p>IFMA’s self-assessment helps you accelerate your career by gaining expertise in the areas
                        you need to become a
                        well-rounded FM professional.</p>

                </div>
                <div class="clearfix"></div>

                <div class="assessment_block auth_subdomain_block">

                    <!-- condition - domain box-->
                    <div class="row" id="domain_block">
                        <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <h5 class="domain_title float-start mt-3"><span>Domains</span></h5>
                            <div class="clearfix"></div>
                            <p class="mb-3">For each Domain listed below, you'll find multiple Subdomains,
                                each containing several competencies.
                                <br>
                        </div>
                        <div *ngFor="let domain of assessmentData; let d_i=index;"
                            class="col-12 col-xl-4 col-xxl-4 col-lg-6 col-md-6 col-sm-12 d-flex border_line">
                            <div class="domain_block auth_domain_block">

                                <div class="content_block progressbar">

                                    <h5>{{domain.name}}</h5>
                                
                                    <p><i class="hash">#</i><b>Total Subdomains:</b>{{domain.subdomain.length}}</p>
                                    <p><i class="hash">#</i><b>Total Competencies:</b>{{domain.no_of_question}}
                                    </p>
                                    <p class="d-flex flex-column" *ngIf = "domain.domain_description === '' ? '' :'domain.domain_description' ;" ><b>Description:</b>{{domain.domain_description}}</p>

                                </div>
                                <div kendoTooltip class="button_block">
                                    <div class="actions_block">
                                        <button *ngIf="domain.progress == 0" (click)="getSubDomain(domain,d_i)"
                                            class="all_btn theme_btn">View Subdomains</button>
                                        <button *ngIf="domain.progress > 0 && domain.progress < 100"
                                            (click)="getSubDomain(domain,d_i)"
                                            class="all_btn theme_btn">Continue</button>
                                        <button *ngIf="domain.progress == 100" (click)="getSubDomain(domain,d_i)"
                                            class="all_btn theme_btn">Review</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end - domain box-->

                    <!-- condition - subdomain box-->
                    <div id="subdomain_block">
                        <div class="row">
                            <div class="col-12 col-xl-3 col-xxl-2 col-lg-12 col-md-12">

                                <h5 class="domain_title">
                                    <span>Domains</span>
                                    <button title="Return to Domains page" (click)="getDomain()" kendoTooltip><i
                                            class="fa fa-list"></i>Overview</button>

                                </h5>
                                <ul class="nav nav-tabs domain_assessment_block" id="myTab_domain" role="tablist">
                                    <li class="nav-item" *ngFor="let domain of assessmentData; let d_i=index;">
                                        <a (click)="domainChangeFormSubd(domain,d_i)" class="nav-link domainc"
                                            id="domain{{d_i}}-tab" data-bs-toggle="tab" href="#domain{{d_i}}" role="tab"
                                            aria-controls="domain1" aria-selected="true">
                                            <div class="title_domain_block">

                                                <h5 class="float-start domain_title mb-0">
                                                    {{domain.name}}
                                                </h5>
                                                <i class="fa fa-angle-right"></i>
                                            </div>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                            <div class="col-12 col-xl-9 col-xxl-10 col-lg-12 col-md-12">
                                <div class="row">
                                    <div class="col-12 col-xl-12 col-lg-12">
                                        <h5 class="float-start domain_title subdomain_title">
                                            <span>{{currentDomain.name}}: Subdomains</span>
                                        </h5>
                                        
                                        <div class="clearfix"></div>

                                    </div>
                                </div>


                                <div class="clearfix"></div>
                                <div class="tab-content" id="myTabContent_domain">
                                    <div id="domain1" role="tabpanel" aria-labelledby="domain1-tab">
                                        <div *ngIf="!IsGridView" class="card_block active_view">
                                            <div class="row">
                                                <div *ngFor="let subdomain of currentDomain.subdomain1; let sd_i=index;"
                                                    class="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-12 d-flex border_line">

                                                    <div class="domain_block">
                                                        <div class="content_block progressbar">

                                                            <kendo-progressbar [value]="subdomain.attempt_question"
                                                                [max]="subdomain.no_of_question"
                                                                [label]="createLabelFormatter(subdomain.no_of_question, subdomain.progress)"></kendo-progressbar>
                                                            <h5>{{subdomain.name}}</h5>

                                                            <p><i class="hash">#</i><b>Total
                                                                    Items:</b>{{subdomain.questions.length}}</p>
                                                            <p *ngIf="subdomain.start_time!=null"><i
                                                                    class="fa fa-calendar"></i><b>Started
                                                                    On:</b>{{subdomain.start_time |
                                                                        date:"MMMM d, yyyy hh:mm a" : 'UTC'}}</p>
                                                            <p *ngIf="subdomain.progress != 0"><i
                                                                    class="fa fa-calendar"></i><b>Last Accessed
                                                                    On:</b>{{subdomain.updated_at |
                                                                        date:"MMMM d, yyyy hh:mm a" : 'UTC'}}</p>

                                                        </div>
                                                        <div kendoTooltip class="button_block">
                                                            <div class="actions_block">
                                                                <button *ngIf="subdomain.progress == 0"
                                                                    (click)="getItems(sd_i)" kendoButton title="Start"
                                                                    class="icon_btn"><i class="fa fa-play"></i></button>
                                                                <button
                                                                    *ngIf="subdomain.progress > 0 && subdomain.progress < 100"
                                                                    (click)="getItems(sd_i)" kendoButton
                                                                    title="Continue" class="icon_btn"><i
                                                                        class="fa fa-play"></i></button>
                                                                <button *ngIf="subdomain.progress == 100"
                                                                    (click)="getItems(sd_i)" kendoButton title="Review"
                                                                    class="icon_btn"><i class="fa fa-eye"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="IsGridView" class="grid_block active_view mb-5">
                                            <div class="row">
                                                <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <kendo-grid [data]="currentDomain.subdomain1" scrollable="none">
                                                        
                                                        <kendo-grid-column field="name" title="Subdomain"
                                                             width="250"></kendo-grid-column>
                                                        <kendo-grid-column title="Description" width="350"
                                                            >
                                                            <ng-template kendoGridCellTemplate let-dataItem
                                                                let-rowIndex="rowIndex">
                                                                {{dataItem.subdomain_description}}
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column title="Total Competencies"
                                                            width="190" class="text-center" 
                                                            [headerStyle]="{'text-align': 'center'}">
                                                            <ng-template kendoGridCellTemplate let-dataItem
                                                                let-rowIndex="rowIndex">
                                                                {{dataItem?.questions?.length}}
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        
                                                        <kendo-grid-column title="Action" width="70"
                                                            class="text-center" [headerStyle]="{'text-align': 'center'}"
                                                           >
                                                            <ng-template kendoGridCellTemplate let-dataItem
                                                                let-rowIndex="rowIndex">
                                                                <div kendoTooltip class="button_block">
                                                                    <div class="actions_block subdomain_action">
                                                                        <button *ngIf="dataItem.progress == 0"
                                                                            (click)="getItems(rowIndex)" kendoButton
                                                                            class="icon_btn blue">View</button>
                                                                        <button
                                                                            *ngIf="dataItem.progress > 0 && dataItem.progress < 100"
                                                                            (click)="getItems(rowIndex)" kendoButton
                                                                            class="icon_btn blue">Continue</button>
                                                                        <button *ngIf="dataItem.progress == 100"
                                                                            (click)="getItems(rowIndex)" kendoButton
                                                                            class="icon_btn blue">Review</button>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        
                                                    </kendo-grid>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="domain2" role="tabpanel"
                                        aria-labelledby="domain2-tab"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end - subdomain box-->

                    <!-- condition - items box -->
                    <div class="row mb-5" id="item_block">
                        <div class="col-12 col-xl-3 col-xxl-2 col-lg-12 col-md-12">
                            <h5 class="domain_title">
                                <span>Domains</span>
                                <button title="Return to Domains page" (click)="getDomain()" kendoTooltip><i
                                        class="fa fa-list"></i>Overview</button>

                                
                            </h5>
                            

                            <ul class="nav nav-tabs domain_assessment_block" id="myTab_ass" role="tablist">
                                <li class="nav-item domain_items" *ngFor="let domain of assessmentData; let d_i=index;">
                                    <a (click)="domainChangeFromItem(domain,d_i)" class="nav-link domainc"
                                        id="assdomain{{d_i}}-tab" data-bs-toggle="tab" href="#assdomain{{d_i}}"
                                        role="tab" aria-controls="assdomain" aria-selected="true">
                                        <div class="title_domain_block">


                                            <h5 class="float-start domain_title mb-0 mt-2">
                                                {{domain.name}}
                                            </h5>
                                            <i class="fa fa-angle-right"></i>
                                        </div>
                                    </a>
                                </li>

                            </ul>

                            
                                        <kendo-dialog *ngIf="opened" (close)="close('cancel')" [minWidth]="250"
                                            [width]="650">
                                            <kendo-dialog-titlebar>
                                                <h5 class="modal-title">5-Point Proficiency Rating Scale</h5>
                                            </kendo-dialog-titlebar>
                                            <div kendoDialogContainer>
                                                <p class="mb-3 pb-3 border-bottom">
                                                    <strong class="d-block">1. Novice</strong>
                                                    Your knowledge and understanding of basic skill techniques and
                                                    concepts is limited. Your situational perception (the
                                                    ability to identify, interpret, and respond to a given circumstance)
                                                    is low, and you do not yet exercise discretionary
                                                    (independent) judgment. You would not be able to perform this skill
                                                    without supervision.
                                                </p>
                                                <p class="mb-3 pb-3 border-bottom">
                                                    <strong class="d-block">2. Foundational</strong>
                                                    Your knowledge and understanding of basic skill techniques and
                                                    concepts is at an early level of development, either
                                                    gained in a classroom, in experimental scenarios or in real
                                                    situations. You understand the context and can apply
                                                    guidelines to determine actions.
                                                </p>
                                                <p class="mb-3 pb-3 border-bottom">
                                                    <strong class="d-block">3. Competent</strong>
                                                    You have the necessary knowledge and understanding of skill
                                                    techniques and concepts to complete tasks in this competency
                                                    as requested. You see actions partially in terms of long-term goals.
                                                    You are conscious and deliberate in planning and
                                                    execution, creating routines and procedures.
                                                </p>
                                                <p class="mb-3 pb-3 border-bottom">
                                                    <strong class="d-block">4. Proficient</strong>
                                                    You have advanced knowledge and understanding of skill techniques
                                                    and concepts and can perform tasks in this competency
                                                    without assistance. You see situations holistically, prioritizing
                                                    the importance of how elements connect within a
                                                    complete system. You have the independence to deviate from normal
                                                    patterns purposefully and use established principles
                                                    for guidance.
                                                </p>
                                                <p>
                                                    <strong class="d-block">5. Expert</strong>
                                                    You have authoritative knowledge and skill in this competency and
                                                    are seen as an expert in this area, perhaps as an
                                                    instructor, author, or speaker on this topic. You are not limited by
                                                    a reliance on rules due to your intuitive grasp of
                                                    situations based on deep tacit knowledge and a vision of what is
                                                    possible.
                                                </p>
                                            </div>
                                        </kendo-dialog>
                                       

                        </div>
                        <div class="col-12 col-xl-9 col-xxl-10 col-lg-12 col-md-12">
                            <div class="tab-content" id="myTabContent_ass">
                                <div id="assdomain1">
                                    <div class="domain_subdomain_block">
                                        <h5 class="float-start domain_title subdomain_title">
                                            <span>{{currentDomain.name}}: Subdomains</span>
                                            <button (click)="getSubDomain(currentDomain,domain_index)"
                                                title="Return to Subdomains page" kendoTooltip><i
                                                    class="fa fa-list"></i>Overview</button>
                                        </h5>

                                        
                                        <div class="clearfix"></div>
                                        <p class="padding_15">
                                        </p>
                                        <div id="thumbs-block" class="owl-carousel">
                                            <ng-container
                                                *ngFor="let subdomain of currentDomain.subdomain1; let sd_i=index;">
                                                <div class="item" [attr.id]="sd_i">
                                                    <div class="sd_box auth_sd_box">

                                                        <p kendoTooltip
                                                            [title]="subdomain.name.length > 60 ? subdomain.name:''"
                                                            position="top">{{
                                                            getSubdomainName(subdomain.name) }} <i
                                                                class="fa fa-angle-down"></i></p>

                                                        
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="grid_items_block">
                                        <div id="main-block" class="owl-carousel hide-next-button hide-prev-button"
                                            >
                                            <ng-container
                                                *ngFor="let subdomain of currentDomain.subdomain1; let sd_i=index;">
                                                <div class="item"
                                                    [ngClass]="{'firstdomain_last': ((sd_i+1)==currentDomain.subdomain1.length ), 'seconddomain_first':(sd_i==0 && currentDomain.subdomain1.length )}">
                                                    <div class="mobile_subdomain">
                                                        <div class="sd_box">

                                                            <kendo-progressbar [value]="subdomain.attempt_question"
                                                                [max]="subdomain.no_of_question"
                                                                [label]="createLabelFormatter(subdomain.no_of_question, subdomain.progress)"></kendo-progressbar>

                                                            
                                                            <p>{{subdomain.name}}<i class="fa fa-angle-down"></i>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <table
                                                        class="table table-bordered table-hover table-striped assessment_table">
                                                        <tbody>
                                                            <tr>
                                                                <th class="text-center">#</th>
                                                                <th>Competency</th>
                                                                <th>Knowledge Statements</th>
                                                                
                                                            </tr>
                                                            <ng-container
                                                                *ngFor="let question of subdomain.questions; let q_i=index;">
                                                                <tr [attr.id]="'item_'+sd_i+'_'+q_i"
                                                                    class="subdomain_items">
                                                                    <td>
                                                                        <div class="number_mobile">{{q_i+1}}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="label">
                                                                            {{question.competency}}</div>
                                                                    </td>
                                                                    <td>
                                                                        <p class="item_text"
                                                                            [innerHtml]="question.question_text"></p>
                                                                    </td>
                                                                   
                                                                </tr>

                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                    
                                                </div>
                                                
                                            </ng-container>
                                        </div>
                                        <div class="text-center">

                                            <button *ngIf="getNextDomainButton()"
                                                class="all_btn theme_btn float-end margin_domain" id="next_btn1"
                                                (click)="domainChangeFormBtn(domain_index + 1)">Next Domain</button>
                                            <button *ngIf="getPreviousDomainButton()" 
                                             class="all_btn themeoption_btn float-start margin_domain"
                                                id="prev_btn" (click)="domainChangeFormBtn(domain_index - 1)">Previous
                                                Domain</button>
                                                
                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="assdomain2" role="tabpanel"
                                    aria-labelledby="assdomain2-tab">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end - items box -->
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="QuestionModal" tabindex="-1" role="dialog" aria-labelledby="QuestionModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title float-start" id="QuestionModalLongTitle">IFMA’s self-assessment Survey</h5>
                <button type="button" class="close float-end" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="clearfix"></div>
                <p class="pt-3">Thank you for taking this survey. Please help us by sharing your feedback on the
                    self-assessment.</p>
            </div>
            <div class="modal-body" *ngIf="SurveyQuestions.length>0">
                <kendo-formfield>
                    <label>{{SurveyQuestions[0].question_text}}</label>
                    <kendo-multiselect [data]="surveyQuestionOptions" [(ngModel)]="SurveyQuestions[0].answer_text"
                        (valueChange)="saveDefaultQuestion(0)" [autoClose]="false">
                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                            <div class="check_box">
                                <input type="checkbox" class="k-checkbox"
                                    [checked]="isSurveyQuestionOptionsSelected(dataItem)">
                                <label class="k-checkbox-label">{{dataItem}}</label>
                            </div>
                        </ng-template>
                    </kendo-multiselect>
                </kendo-formfield>
                <kendo-formfield>
                    <label>{{SurveyQuestions[1].question_text}}</label>
                    <textarea kendoTextArea #answer_text="ngModel" [(ngModel)]="SurveyQuestions[1].answer_text"
                        (change)="saveDefaultQuestion(1)"></textarea>
                </kendo-formfield>
                <kendo-formfield>
                    <label>{{SurveyQuestions[2].question_text}}</label>
                    <div class="rate_stars">

                        <button *ngIf="SurveyQuestions[2].answer_text"
                            (click)="SurveyQuestions[2].answer_text = null; saveDefaultQuestion(2)"
                            class="float-end clear_btn">
                            <i class="fa fa-times"></i>Clear</button>
                        <p *ngIf="SurveyQuestions[2].answer_text == 0" class="float-end inline_text">Very Poorly</p>
                        <p *ngIf="SurveyQuestions[2].answer_text == 1" class="float-end inline_text">Poorly</p>
                        <p *ngIf="SurveyQuestions[2].answer_text == 2" class="float-end inline_text">Adequately</p>
                        <p *ngIf="SurveyQuestions[2].answer_text == 3" class="float-end inline_text">Well</p>
                        <p *ngIf="SurveyQuestions[2].answer_text == 4" class="float-end inline_text">Very Well</p>


                        <input type="radio" id="rating4" value="4" #answer_text="ngModel"
                            [(ngModel)]="SurveyQuestions[2].answer_text" (change)="saveDefaultQuestion(2)" />
                        <label for="rating4" title="Very Well" kendoTooltip></label>

                        <input type="radio" id="rating3" value="3" #answer_text="ngModel"
                            [(ngModel)]="SurveyQuestions[2].answer_text" (change)="saveDefaultQuestion(2)" />
                        <label for="rating3" title="Well" kendoTooltip></label>

                        <input type="radio" id="rating2" value="2" #answer_text="ngModel"
                            [(ngModel)]="SurveyQuestions[2].answer_text" (change)="saveDefaultQuestion(2)" />
                        <label for="rating2" title="Adequately" kendoTooltip></label>

                        <input type="radio" id="rating1" value="1" #answer_text="ngModel"
                            [(ngModel)]="SurveyQuestions[2].answer_text" (change)="saveDefaultQuestion(2)" />
                        <label for="rating1" title="Poorly" kendoTooltip></label>

                        <input type="radio" id="rating0" value="0" #answer_text="ngModel"
                            [(ngModel)]="SurveyQuestions[2].answer_text" (change)="saveDefaultQuestion(2)" />
                        <label for="rating0" title="Very Poorly" kendoTooltip></label>
                    </div>
                </kendo-formfield>
            </div>
            <div class="modal-footer">
                <p>Are you sure you want to submit this survey?</p>
                <button type="button" (click)="submitFinal()" class="all_btn theme_btn">Yes</button>
                <button type="button" data-bs-dismiss="modal" class="all_btn themeoption_btn">No</button>
            </div>
        </div>
    </div>
</div>