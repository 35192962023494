<div class="inner_content_block landing_dashboard">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="breadcrumb_block">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a routerLink="/dashboard">
                                Home
                            </a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Landing Dashboard</li>
                    </ol>
                </div>
                <!-- <div class="current_institute_block"
                    *ngIf="(globals.institute_details && globals.institute_details.institute && globals.institute_details.institute.id!='')">
                    <b>Current Organization:</b>{{globals.institute_details.institute.institute_name}} <span *ngIf="globals.institute_details.institute.provider_id"
                        class="id_box mb-0">{{globals.institute_details.institute.provider_id}}</span><a routerLink="/landing-dashboard" class="ms-3 switch_button pe-3" *ngIf="UserRoleList.length>1">Switch Organization</a>
                </div> -->
                <div class="clearfix"></div>
                <div class="title_block">
                    <h1 class="float-start">Your Products</h1>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                <div class="grey_form_block" *ngIf="ProductList.length>0">
                
                    <h5 class="text-center mb-5">Please select a Products</h5>
                    <div class="clearfix"></div>
                    <div class="role_radio_block mb-4">
                        <div class="row justify-content-center">

                            <div class="col-12 col-xl-2 col-lg-4 col-md-6 col-sm-12" *ngFor="let product of ProductList;">
                                <div class="role_radio">
                                    <label for="oess">
                                        <a href="{{product.web_url}}" target="_blank">
                                            {{product.product_name}}
                                        </a>
                                    </label>
                                </div>
                            </div>                           
                           
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div> 
               
            </div>
        </div>
    </div>
</div>