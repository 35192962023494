import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Globals } from '../globals';
import { CookieService } from 'ngx-cookie-service';
declare var $,Swal: any;


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  ProductList:any = [];

  constructor(public globals: Globals,private AuthService: AuthService, private router: Router, 
    private fb: UntypedFormBuilder, private CookieService: CookieService) { }

  ngOnInit(): void { 
    this.globals.isLoading = true;
    let institute_id = (this.globals.institute_details && this.globals.institute_details.institute != null) ? this.globals.institute_details.institute.id : null;
    let postdata = {'user_id': this.globals.authData.id,'institute_id': institute_id,'role_value':this.globals.institute_details.role_value};
    this.AuthService.getUserProductList(postdata)
    .then((data) => { 
      this.ProductList = data['data'];
      this.globals.isLoading = false;
    },
    (error) => {
      this.globals.isLoading = false;
      this.globals.errorSweetAlert();
    });
    
  }

}
