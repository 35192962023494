import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Globals } from './globals';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorClassService } from './http-interceptor-class.service';

// Components
import { IntermediatePageComponent } from './intermediate-page/intermediate-page.component';
import { LandingDashboardComponent } from './landing-dashboard/landing-dashboard.component';
import { ProductListComponent } from './product-list/product-list.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthenticationFailedComponent } from './authentication-failed/authentication-failed.component';

// Services
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { ConfigurationService } from './services/configuration.service';

const routes: Routes = [
  { path: '', redirectTo: 'landing-dashboard', pathMatch: 'full' },
  //{ path: 'authentication/:token/:product', component: IntermediatePageComponent, canActivate: [AuthGuard] }, // Check Authentication form product 
  { path: 'authentication', component: IntermediatePageComponent, canActivate: [AuthGuard], data: { title: 'Authentication' } }, // Check Authentication
  //{ path: 'landing-dashboard/:product', component: LandingDashboardComponent, canActivate: [AuthGuard] }, // Roles from product
  { path: 'landing-dashboard', component: LandingDashboardComponent, canActivate: [AuthGuard], data: { title: 'Landing Dashboard' } }, // Roles

  //{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] }, // Roles

  //{ path: 'product-list', component: ProductListComponent, canActivate: [AuthGuard] }, // Product-list
  //{ path: 'admin/login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'page-not-found', component: NotFoundComponent, data: { title: 'Page Not Found' } },
  { path: 'authentication-failed', component: AuthenticationFailedComponent, data: { title: 'Authentication Failed' } },
  { path: '**', redirectTo: 'landing-dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [Globals, AuthService, AuthGuard, ConfigurationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorClassService,
      multi: true
    }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
