import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from "@angular/common";
import { SharedModule } from './app-shared/app-shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { IntermediatePageComponent } from './intermediate-page/intermediate-page.component';
import { LandingDashboardComponent } from './landing-dashboard/landing-dashboard.component';
import { ProductListComponent } from './product-list/product-list.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';

// Kendo-UI Elements
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { LabelModule } from '@progress/kendo-angular-label';
import { EditorModule } from '@progress/kendo-angular-editor';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthenticationFailedComponent } from './authentication-failed/authentication-failed.component';

@NgModule({ declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        IntermediatePageComponent,
        LandingDashboardComponent,
        ProductListComponent,
        NotFoundComponent,
        LoginComponent,
        DashboardComponent,
        AuthenticationFailedComponent
    ],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        SharedModule,
        AppRoutingModule,
        // Kendo Elements
        ButtonsModule,
        InputsModule,
        TooltipModule,
        LabelModule,
        EditorModule,
        DropDownsModule], providers: [SharedModule, Title, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
