<div class="inner_content_block assessment_main_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">

                <div class="title_block">
                    <h1 class="float-start">IFMA's self-assessment</h1>
                    <button class="all_btn themeoption_btn float-end" (click)="backToSubscription()"
                        kendoTooltip>Back</button>
                    <!-- <button class="all_btn theme_btn float-end help_btn" (click)="backToMain()"
                        kendoTooltip>Purchase Subscription</button> -->

                        <button class="all_btn theme_btn float-end help_btn" *ngIf="is_purchased <= 0" (click)="backToMain()">
                            Purchase Subscription</button>
                    <!-- <button  class="all_btn theme_btn float-end help_btn"  (click)="open()" kendoTooltip>Rating Guide</button> -->
                    <button id="help_btn" class="all_btn theme_btn float-end help_btn" (click)="open()">
                        Rating Guide <i class="fa fa-info-circle ms-2"></i>
                    </button>
                    <!-- <button id="help_btn" class="all_btn theme_btn float-end help_btn" (click)="help(null)">
                        Tour Guide <i class="fa fa-info-circle ms-2"></i>
                    </button> -->

                    <!-- <button id="help_btn" class="all_btn theme_btn float-end help_btn" (click)="help(null)">
                        Tour Guide <i class="fa fa-info-circle ms-2"></i>
                    </button> -->
                    <!--<div class="detail_block float-start">
                        <div class="detail_width"
                            *ngIf="assessmentDetails.institute_name!=null && (globals.institute_details && globals.institute_details.institute && globals.institute_details.institute.id!='')">
                            <div class="icon_text_block">
                                <i class="fa fa-building"></i>
                                <span>Organization:</span> AMSN (Academy of Medical-Surgical Nurses)
                            </div>
                        </div> -->
                    <!-- <div class="detail_width">
                            <div class="icon_text_block">
                                <i class="fa fa-calendar"></i>
                                <span>Assigned on:</span> June 15, 2015
                            </div>
                        </div> 
                    </div>-->
                    <!-- <button kendoButton routerLink="/assessment/list" class="all_btn themewhite_btn float-end">List of Assessments</button> -->

                    <div class="clearfix"></div>

                    <p class="my-3">IFMA's self-assessment considers your skills and creates a blueprint for your FM
                        training. This
                        exercise will help you
                        identify your strengths and weaknesses across the FM knowledge base. After answering the
                        questions, you'll have a clear
                        understanding of your specific knowledge gaps.</p>
                    <p>IFMA’s self-assessment helps you accelerate your career by gaining expertise in the areas
                        you need to become a
                        well-rounded FM professional.</p>

                </div>
                <div class="clearfix"></div>
                <!-- <div class="progress_block"> -->
                <!-- <kendo-progressbar [value]="assessmentDetails.attempt_question"
                        [max]="assessmentDetails.no_of_question" [label]="label" data-title="Progress"
                        data-intro='You can track your progress to completion here.'></kendo-progressbar> -->
                <!-- </div> -->
                <!-- <div class="clearfix"></div> -->

                <div class="assessment_block auth_subdomain_block">

                    <!-- instructions -->
                    <!-- <div class="row">
                        <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                            <div class="title_instruction_block"> -->
                    <!-- <h5 class="domain_title float-start mt-0 mb-0" *ngIf="!currentDomain.name">
                                    <span>Domains</span></h5> -->
                    <!-- condition - visible when sub-domain or item  -->
                    <!-- <div class="float-start" data-title="Domains" data-intro='This demonstrates your progress to completion of the domain.'>
                                <div *ngIf="currentDomain.name" class="circle circle_domain float-start"
                                    id="current_domain_circle">
                                    <strong></strong>
                                </div>
                                <h5 *ngIf="currentDomain.name" class="float-start domain_title mb-0 mt-2">
                                    <span>Domain: </span>{{currentDomain.name}}<button (click)="getDomain()"><i
                                            class="fa fa-exchange"></i>Change
                                        Domain</button>
                                </h5>
                                </div> -->
                    <!-- end - visible when sub-domain or item  -->

                    <!-- <button class="all_btn themeoption_btn float-end instr_btn" data-bs-toggle="collapse" data-title="Instructions" data-intro='Complete the items for each subdomain and domain according to the rating scale provided. Please complete all items honestly and accurately for best results.' data-position ="left"
                                    href="#Intro_block" role="button" aria-expanded="true" aria-controls="Intro_block">
                                    Instructions <i class="fa fa-angle-up"></i>
                                </button> -->

                    <!-- <div class="clearfix"></div> -->
                    <!-- <div class="collapse show" id="Intro_block">
                                    <div class="card card-body">
                                        <p *ngIf="domain_block">There are two domains of practice within this self-assessment. Within each domain there are several subdomains that you will complete to measure your overall knowledge, skills and abilities.
                                        </p>
                                        <p *ngIf="domain_block">This page shows you your progress on each of the domains, as well as when you last accessed the self-assessment.
                                        </p>
                                        <p *ngIf="subdomain_block">Within this domain there are several subdomains, shown below. The progress within each subdomain is presented, as is the total number of items in each subdomain and the date you last accessed each.</p>
                                        
                                        <p class="padding_15" *ngIf="item_block">For each item below, please rate your knowledge, skill and ability to carry out the item right now according to the scale provided.</p>
                                        <ng-container *ngIf="item_block">
                                            <h5>Ratings</h5>
                                            <ul>
                                                <li><b>1</b>- Cannot perform the task</li>
                                                <li><b>2</b>- Requires significant guidance to complete the task</li>
                                                <li><b>3</b>- Requires some guidance to complete the task</li>
                                                <li><b>4</b>- Requires no guidance to complete the task</li>
                                            </ul>
                                        </ng-container>
                                    </div>
                                </div> -->
                    <!-- <div class="clearfix"></div> -->
                    <!-- </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="clearfix"></div>
                    </div> -->

                    <!-- condition - domain box-->
                    <div class="row" id="domain_block">
                        <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <h5 class="domain_title float-start mt-3"><span>Domains</span></h5>
                            <div class="clearfix"></div>
                            <p class="mb-3">For each Domain listed below, you'll find multiple Subdomains,
                                each containing several Knowledge Statements.
                                <br>
                                <!-- <p class="padding_15">PLEASE NOTE: Your results will be saved periodically to allow you to
                                return if you are unable to complete the assessment in one sitting.
                            </p> -->
                                <!-- <p class="padding_15">This page shows you your progress on each of the domains, as well as when you last accessed the self-assessment.
                            </p> -->
                        </div>
                        <div *ngFor="let domain of assessmentData; let d_i=index;"
                            class="col-12 col-xl-4 col-xxl-4 col-lg-6 col-md-6 col-sm-12 d-flex border_line">
                            <div class="domain_block auth_domain_block">

                                <div class="content_block progressbar">


                                    <!-- <kendo-progressbar
                                        [label]="createLabelFormatter(domain.no_of_question, domain.progress)"
                                        [value]="domain.attempt_question"
                                        [max]="domain.no_of_question"></kendo-progressbar> -->
                                    <h5>{{domain.name}}</h5>
                                    <!-- <div class="circle" id="Domain{{d_i}}"><strong data-bs-toggle="tooltip"
                                            data-placement="bottom"></strong></div> -->
                                    <p><i class="hash">#</i><b>Total Subdomains:</b>{{domain.subdomain.length}}</p>
                                    <p><i class="hash">#</i><b>Total Knowledge Statements:</b>{{domain.no_of_question}}
                                    </p>
                                    <p class="d-flex flex-column" *ngIf = "domain.domain_description === '' ? '' :'domain.domain_description' ;" ><b>Description:</b>{{domain.domain_description}}</p>
                                   
                                    <!-- <p *ngIf="domain.start_time!=null"><i class="fa fa-calendar"></i><b>Started
                                            On:</b>{{domain.start_time | date:"MMMM d, yyyy hh:mm a"}}</p>
                                    <p *ngIf="domain.start_time==null"><i class="fa fa-calendar"></i><b>Started
                                            On:</b>Not started yet</p>
                                    <p *ngIf="domain.progress != 0"><i class="fa fa-calendar"></i><b>Last Accessed
                                            On:</b>{{domain.updated_at | date:"MMMM d, yyyy hh:mm a"}}</p>
                                    <p *ngIf="domain.progress == 0"><i class="fa fa-calendar"></i><b>Last Accessed
                                            On:</b>-</p> -->

                                </div>
                                <div kendoTooltip class="button_block">
                                    <div class="actions_block">
                                        <button *ngIf="domain.progress == 0" (click)="getSubDomain(domain,d_i)"
                                            class="all_btn theme_btn">View Subdomains</button>
                                        <button *ngIf="domain.progress > 0 && domain.progress < 100"
                                            (click)="getSubDomain(domain,d_i)"
                                            class="all_btn theme_btn">Continue</button>
                                        <button *ngIf="domain.progress == 100" (click)="getSubDomain(domain,d_i)"
                                            class="all_btn theme_btn">Review</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end - domain box-->

                    <!-- condition - subdomain box-->
                    <div id="subdomain_block">
                        <div class="row">
                            <div class="col-12 col-xl-3 col-xxl-2 col-lg-12 col-md-12">

                                <h5 class="domain_title">
                                    <span>Domains</span>
                                    <button title="Return to Domains page" (click)="getDomain()" kendoTooltip><i
                                            class="fa fa-list"></i>Overview</button>

                                </h5>
                                <ul class="nav nav-tabs domain_assessment_block" id="myTab_domain" role="tablist">
                                    <li class="nav-item" *ngFor="let domain of assessmentData; let d_i=index;">
                                        <a (click)="domainChangeFormSubd(domain,d_i)" class="nav-link domainc"
                                            id="domain{{d_i}}-tab" data-bs-toggle="tab" href="#domain{{d_i}}" role="tab"
                                            aria-controls="domain1" aria-selected="true">
                                            <div class="title_domain_block">

                                                <!-- <kendo-progressbar [value]="domain.attempt_question"
                                                    [max]="domain.no_of_question"
                                                    [label]="createLabelFormatter(domain.no_of_question, domain.progress)"></kendo-progressbar> -->

                                                <!-- <div class="circle circle_domain float-start" id="domain_circle{{d_i}}">
                                                    <strong></strong>
                                                </div> -->
                                                <h5 class="float-start domain_title mb-0">
                                                    {{domain.name}}
                                                </h5>
                                                <i class="fa fa-angle-right"></i>
                                            </div>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                            <div class="col-12 col-xl-9 col-xxl-10 col-lg-12 col-md-12">
                                <div class="row">
                                    <div class="col-12 col-xl-12 col-lg-12">
                                        <h5 class="float-start domain_title subdomain_title">
                                            <span>{{currentDomain.name}}: Subdomains</span>
                                        </h5>
                                        <!-- <div class="float-end" *ngIf="currentDomain.progress<100">
                                            <div class="check_box">
                                                <input type="checkbox" id="completed_hide" [(ngModel)]="HideCompleted"
                                                    name="completed_hide" (change)="changeHide(0)">
                                                <label for="completed_hide">Hide all Completed</label>
                                            </div>
                                        </div> -->
                                        <div class="clearfix"></div>
                                        <!-- <div class="view_block mb-4 float-end" kendoTooltip>
                                            <kendo-formfield class="label_switch">
                                                <span class="text">List view</span>
                                                <kendo-switch #is_active [onLabel]="''" [offLabel]="''"
                                                    [(ngModel)]="!IsGridView" (valueChange)="viewChange($event)">
                                                </kendo-switch>
                                                <span class="text">Card view</span>
                                            </kendo-formfield>
                                            <div class="clearfix"></div>
                                        </div> -->
                                        <!-- <div class="clearfix"></div>
                                        <p class="padding_15 desktop_p"></p>
                                        <p class="padding_15 mobile_p"> Within this domain, you can view the number of
                                            subdomains and track your progress in completing each one, including the
                                            start date and the last accessed date for each Subdomain. The action column
                                            lets you start rating Competencies, continue where you left off, or review
                                            previously rated statements.</p> -->


                                    </div>
                                </div>


                                <div class="clearfix"></div>
                                <div class="tab-content" id="myTabContent_domain">
                                    <div id="domain1" role="tabpanel" aria-labelledby="domain1-tab">
                                        <div *ngIf="!IsGridView" class="card_block active_view">
                                            <div class="row">
                                                <div *ngFor="let subdomain of currentDomain.subdomain1; let sd_i=index;"
                                                    class="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-12 d-flex border_line">

                                                    <div class="domain_block">
                                                        <div class="content_block progressbar">

                                                            <kendo-progressbar [value]="subdomain.attempt_question"
                                                                [max]="subdomain.no_of_question"
                                                                [label]="createLabelFormatter(subdomain.no_of_question, subdomain.progress)"></kendo-progressbar>
                                                            <h5>{{subdomain.name}}</h5>


                                                            <!-- <div class="circle" id="SubDomain{{sd_i}}"><strong
                                                                    data-bs-toggle="tooltip"
                                                                    data-placement="bottom"></strong>
                                                            </div> -->
                                                            <p><i class="hash">#</i><b>Total
                                                                    Items:</b>{{subdomain.questions.length}}</p>
                                                            <p *ngIf="subdomain.start_time!=null"><i
                                                                    class="fa fa-calendar"></i><b>Started
                                                                    On:</b>{{subdomain.start_time |
                                                                        date:"MMMM d, yyyy hh:mm a" : 'UTC'}}</p>
                                                            <p *ngIf="subdomain.progress != 0"><i
                                                                    class="fa fa-calendar"></i><b>Last Accessed
                                                                    On:</b>{{subdomain.updated_at |
                                                                        date:"MMMM d, yyyy hh:mm a" : 'UTC'}}</p>

                                                        </div>
                                                        <div kendoTooltip class="button_block">
                                                            <div class="actions_block">
                                                                <button *ngIf="subdomain.progress == 0"
                                                                    (click)="getItems(sd_i)" kendoButton title="Start"
                                                                    class="icon_btn"><i class="fa fa-play"></i></button>
                                                                <button
                                                                    *ngIf="subdomain.progress > 0 && subdomain.progress < 100"
                                                                    (click)="getItems(sd_i)" kendoButton
                                                                    title="Continue" class="icon_btn"><i
                                                                        class="fa fa-play"></i></button>
                                                                <button *ngIf="subdomain.progress == 100"
                                                                    (click)="getItems(sd_i)" kendoButton title="Review"
                                                                    class="icon_btn"><i class="fa fa-eye"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="IsGridView" class="grid_block active_view mb-5">
                                            <div class="row">
                                                <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <kendo-grid [data]="currentDomain.subdomain1" scrollable="none">
                                                        <!-- <kendo-grid-column field="name" title="Subdomain"
                                                            media="(min-width: 1500px)">
                                                        </kendo-grid-column> -->
                                                        <!-- <kendo-grid-column field="name" title="Subdomain"
                                                            media="(min-width: 991px) and (max-width: 1499px)"
                                                            min-width="175">
                                                        </kendo-grid-column> -->
                                                        <kendo-grid-column field="name" title="Subdomain"
                                                             width="250"></kendo-grid-column>
                                                        <kendo-grid-column title="Description" width="350"
                                                            >
                                                            <ng-template kendoGridCellTemplate let-dataItem
                                                                let-rowIndex="rowIndex">
                                                                {{dataItem.subdomain_description}}
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column title="Total Knowledge Statements"
                                                            width="190" class="text-center" 
                                                            [headerStyle]="{'text-align': 'center'}">
                                                            <ng-template kendoGridCellTemplate let-dataItem
                                                                let-rowIndex="rowIndex">
                                                                {{dataItem?.questions?.length}}
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <!-- <kendo-grid-column field="start_time" width="180"
                                                            title="Started On" >
                                                            <ng-template kendoGridCellTemplate let-dataItem
                                                                let-rowIndex="rowIndex">
                                                                <span
                                                                    *ngIf="dataItem.start_time!=null">{{dataItem.start_time
                                                                    | date:"MMMM d, yyyy hh:mm a"}}</span>
                                                                <span *ngIf="dataItem.start_time==null">Not started
                                                                    yet</span>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="complete_time" width="180"
                                                            title="Last Accessed On" >
                                                            <ng-template kendoGridCellTemplate let-dataItem
                                                                let-rowIndex="rowIndex">
                                                                <span
                                                                    *ngIf="dataItem.progress != 0">{{dataItem.updated_at
                                                                    | date:"MMMM d, yyyy hh:mm a"}}</span>
                                                                <span *ngIf="dataItem.progress == 0">-</span>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="start_time" width="100"
                                                            title="Progress" >
                                                            <ng-template kendoGridCellTemplate let-dataItem>

                                                                <kendo-progressbar [value]="dataItem.progress"
                                                                    [label]="label1">
                                                                </kendo-progressbar>
                                                            </ng-template>
                                                        </kendo-grid-column> -->
                                                        <!-- <kendo-grid-column field="start_time" width="70"
                                                            title="Progress">
                                                            <ng-template kendoGridCellTemplate let-dataItem>

                                                                <kendo-progressbar [value]="dataItem.progress"
                                                                    [label]="label1">
                                                                </kendo-progressbar>
                                                            </ng-template>
                                                        </kendo-grid-column> -->
                                                        <kendo-grid-column title="Action" width="70"
                                                            class="text-center" [headerStyle]="{'text-align': 'center'}"
                                                           >
                                                            <ng-template kendoGridCellTemplate let-dataItem
                                                                let-rowIndex="rowIndex">
                                                                <div kendoTooltip class="button_block">
                                                                    <div class="actions_block subdomain_action">
                                                                        <button *ngIf="dataItem.progress == 0"
                                                                            (click)="getItems(rowIndex)" kendoButton
                                                                            class="icon_btn blue">View</button>
                                                                        <button
                                                                            *ngIf="dataItem.progress > 0 && dataItem.progress < 100"
                                                                            (click)="getItems(rowIndex)" kendoButton
                                                                            class="icon_btn blue">Continue</button>
                                                                        <button *ngIf="dataItem.progress == 100"
                                                                            (click)="getItems(rowIndex)" kendoButton
                                                                            class="icon_btn blue">Review</button>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <!-- <kendo-grid-column title="Action" width="70" class="text-center"
                                                            [headerStyle]="{'text-align': 'center'}"
                                                            >
                                                            <ng-template kendoGridCellTemplate let-dataItem
                                                                let-rowIndex="rowIndex">
                                                                <div kendoTooltip class="button_block">
                                                                    <div class="actions_block subdomain_action">
                                                                        <button *ngIf="dataItem.progress == 0"
                                                                            (click)="getItems(rowIndex)" kendoButton
                                                                            class="icon_btn blue">View</button>
                                                                        <button
                                                                            *ngIf="dataItem.progress > 0 && dataItem.progress < 100"
                                                                            (click)="getItems(rowIndex)" kendoButton
                                                                            class="icon_btn blue">Continue</button>
                                                                        <button *ngIf="dataItem.progress == 100"
                                                                            (click)="getItems(rowIndex)" kendoButton
                                                                            class="icon_btn blue">Review</button>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </kendo-grid-column> -->
                                                    </kendo-grid>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="domain2" role="tabpanel"
                                        aria-labelledby="domain2-tab"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end - subdomain box-->

                    <!-- condition - items box -->
                    <div class="row mb-5" id="item_block">
                        <div class="col-12 col-xl-3 col-xxl-2 col-lg-12 col-md-12">
                            <h5 class="domain_title">
                                <span>Domains</span>
                                <button title="Return to Domains page" (click)="getDomain()" kendoTooltip><i
                                        class="fa fa-list"></i>Overview</button>

                                <!-- <button (click)="getDomain()"><i class="fa fa-exchange"></i>Change
                                    Domain</button> -->
                            </h5>
                            <!-- <ul class="nav nav-tabs domain_assessment_block" id="myTab_ass" role="tablist">
                                <li class="nav-item domain_items" id="assdomain_{{d_i}}"
                                    *ngFor="let domain of assessmentData; let d_i=index;" data-title="Domains"
                                    data-intro='This demonstrates your progress to completion of the domain.'>
                                    <a (click)="domainChangeFromItem(domain,d_i)" class="nav-link domainc"
                                        id="assdomain{{d_i}}-tab" data-bs-toggle="tab" href="#assdomain{{d_i}}"
                                        role="tab" aria-controls="assdomain" aria-selected="true">
                                        <div class="title_domain_block">

                                       
                                            <h5 class="float-start domain_title mb-0 mt-2">
                                                {{domain.name}}
                                            </h5>
                                            <i class="fa fa-angle-right"></i>
                                        </div>
                                    </a>
                                </li>

                            </ul> -->

                            <ul class="nav nav-tabs domain_assessment_block" id="myTab_ass" role="tablist">
                                <li class="nav-item domain_items" *ngFor="let domain of assessmentData; let d_i=index;">
                                    <a (click)="domainChangeFromItem(domain,d_i)" class="nav-link domainc"
                                        id="assdomain{{d_i}}-tab" data-bs-toggle="tab" href="#assdomain{{d_i}}"
                                        role="tab" aria-controls="assdomain" aria-selected="true">
                                        <div class="title_domain_block">


                                            <h5 class="float-start domain_title mb-0 mt-2">
                                                {{domain.name}}
                                            </h5>
                                            <i class="fa fa-angle-right"></i>
                                        </div>
                                    </a>
                                </li>

                            </ul>

                            <!-- <ng-container *ngIf="item_block">
                                <div class="rating_guide"> -->
                                    <!-- <div class="title_block d-flex align-items-center justify-content-between">
                                        <h4 class="flex-fill mb-0">Rating Scale</h4>
                                        <button data-title="Rating Scale Information"
                                            data-intro='Click on this info button to read information about to rating scale.'
                                            class="all_btn theme_btn small_btn" (click)="open()">
                                            Info <i class="fa fa-info-circle ms-1"></i>
                                        </button> -->
                                        <kendo-dialog *ngIf="opened" (close)="close('cancel')" [minWidth]="250"
                                            [width]="650">
                                            <kendo-dialog-titlebar>
                                                <h5 class="modal-title">5-Point Proficiency Rating Scale</h5>
                                            </kendo-dialog-titlebar>
                                            <div kendoDialogContainer>
                                                <p class="mb-3 pb-3 border-bottom">
                                                    <strong class="d-block">1. Novice</strong>
                                                    Your knowledge and understanding of basic skill techniques and
                                                    concepts is limited. Your situational perception (the
                                                    ability to identify, interpret, and respond to a given circumstance)
                                                    is low, and you do not yet exercise discretionary
                                                    (independent) judgment. You would not be able to perform this skill
                                                    without supervision.
                                                </p>
                                                <p class="mb-3 pb-3 border-bottom">
                                                    <strong class="d-block">2. Foundational</strong>
                                                    Your knowledge and understanding of basic skill techniques and
                                                    concepts is at an early level of development, either
                                                    gained in a classroom, in experimental scenarios or in real
                                                    situations. You understand the context and can apply
                                                    guidelines to determine actions.
                                                </p>
                                                <p class="mb-3 pb-3 border-bottom">
                                                    <strong class="d-block">3. Competent</strong>
                                                    You have the necessary knowledge and understanding of skill
                                                    techniques and concepts to complete tasks in this competency
                                                    as requested. You see actions partially in terms of long-term goals.
                                                    You are conscious and deliberate in planning and
                                                    execution, creating routines and procedures.
                                                </p>
                                                <p class="mb-3 pb-3 border-bottom">
                                                    <strong class="d-block">4. Proficient</strong>
                                                    You have advanced knowledge and understanding of skill techniques
                                                    and concepts and can perform tasks in this competency
                                                    without assistance. You see situations holistically, prioritizing
                                                    the importance of how elements connect within a
                                                    complete system. You have the independence to deviate from normal
                                                    patterns purposefully and use established principles
                                                    for guidance.
                                                </p>
                                                <p>
                                                    <strong class="d-block">5. Expert</strong>
                                                    You have authoritative knowledge and skill in this competency and
                                                    are seen as an expert in this area, perhaps as an
                                                    instructor, author, or speaker on this topic. You are not limited by
                                                    a reliance on rules due to your intuitive grasp of
                                                    situations based on deep tacit knowledge and a vision of what is
                                                    possible.
                                                </p>
                                            </div>
                                        </kendo-dialog>
                                        <!-- <div class="clearfix"></div>
                                    </div> -->
                                    <!-- <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>Novice</td>
                                                <td class="width_15 text-center">1</td>
                                            </tr>
                                            <tr>
                                                <td>Foundational</td>
                                                <td class="width_15 text-center">2</td>
                                            </tr>
                                            <tr>
                                                <td>Competent</td>
                                                <td class="width_15 text-center">3</td>
                                            </tr>
                                            <tr>
                                                <td>Proficient</td>
                                                <td class="width_15 text-center">4</td>
                                            </tr>
                                            <tr>
                                                <td>Expert</td>
                                                <td class="width_15 text-center">5</td>
                                            </tr>
                                        </tbody>
                                    </table> -->
                                <!-- </div>
                            </ng-container> -->

                        </div>
                        <div class="col-12 col-xl-9 col-xxl-10 col-lg-12 col-md-12">
                            <div class="tab-content" id="myTabContent_ass">
                                <div id="assdomain1">
                                    <div class="domain_subdomain_block">
                                        <h5 class="float-start domain_title subdomain_title">
                                            <span>{{currentDomain.name}}: Subdomains</span>
                                            <button (click)="getSubDomain(currentDomain,domain_index)"
                                                title="Return to Subdomains page" kendoTooltip><i
                                                    class="fa fa-list"></i>Overview</button>
                                        </h5>

                                        <!-- <div class="float-end" *ngIf="currentDomain.progress<100">
                                            <div class="check_box" data-title="Hide all Completed"
                                                data-intro='Select this box if you do not want to see the subdomains that you have completed in their entirety.'
                                                data-position="left">
                                                <input type="checkbox" id="completed_hide" [(ngModel)]="HideCompleted"
                                                    name="completed_hide" (change)="changeHide(0)">
                                                <label for="completed_hide">Hide all Completed</label>
                                            </div>
                                        </div> -->
                                        <div class="clearfix"></div>
                                        <p class="padding_15">
                                        </p>
                                        <div id="thumbs-block" class="owl-carousel">
                                            <ng-container
                                                *ngFor="let subdomain of currentDomain.subdomain1; let sd_i=index;">
                                                <!-- <ng-container *ngIf="((subdomain.progress < 100 && HideCompleted) || (!HideCompleted))"> -->
                                                <div class="item" [attr.id]="sd_i">
                                                    <div class="sd_box auth_sd_box">

                                                        <!-- <kendo-progressbar [value]="subdomain.attempt_question"
                                                            [max]="subdomain.no_of_question"
                                                            [label]="createLabelFormatter(subdomain.no_of_question, subdomain.progress)"></kendo-progressbar> -->

                                                        <!-- <div class="circle" id="SubDomainCard{{sd_i}}">
                                                            <strong></strong>
                                                        </div> -->

                                                        <p kendoTooltip
                                                            [title]="subdomain.name.length > 60 ? subdomain.name:''"
                                                            position="top">{{
                                                            getSubdomainName(subdomain.name) }} <i
                                                                class="fa fa-angle-down"></i></p>

                                                        <!-- <p>{{subdomain.name}}<i class="fa fa-angle-down"></i>
                                                        </p> -->
                                                    </div>
                                                </div>
                                                <!-- </ng-container> -->
                                            </ng-container>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="grid_items_block">
                                        <div id="main-block" class="owl-carousel hide-next-button hide-prev-button"
                                            >
                                            <ng-container
                                                *ngFor="let subdomain of currentDomain.subdomain1; let sd_i=index;">
                                                <!-- <ng-container *ngIf="((subdomain.progress < 100 && HideCompleted) || (!HideCompleted))"> -->
                                                <div class="item"
                                                    [ngClass]="{'firstdomain_last': ((sd_i+1)==currentDomain.subdomain1.length ), 'seconddomain_first':(sd_i==0 && currentDomain.subdomain1.length )}">
                                                    <div class="mobile_subdomain">
                                                        <div class="sd_box">

                                                            <kendo-progressbar [value]="subdomain.attempt_question"
                                                                [max]="subdomain.no_of_question"
                                                                [label]="createLabelFormatter(subdomain.no_of_question, subdomain.progress)"></kendo-progressbar>

                                                            <!-- <div class="circle" id="SubDomainCardMobile{{sd_i}}">
                                                                <strong></strong>
                                                            </div> -->
                                                            <p>{{subdomain.name}}<i class="fa fa-angle-down"></i>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <table
                                                        class="table table-bordered table-hover table-striped assessment_table">
                                                        <tbody>
                                                            <tr>
                                                                <th class="text-center">#</th>
                                                                <th>Competency</th>
                                                                <th>Knowledge Statements</th>
                                                                <!-- <th class="text-center width_15"
                                                                    [attr.data-title]="ratingList[0].display_text"
                                                                    [attr.data-intro]="ratingList[0].description">
                                                                    {{ratingList[0].display_text}}</th>
                                                                <th class="text-center width_15"
                                                                    [attr.data-title]="ratingList[1].display_text"
                                                                    [attr.data-intro]="ratingList[1].description">
                                                                    {{ratingList[1].display_text}}</th>
                                                                <th class="text-center width_15"
                                                                    [attr.data-title]="ratingList[2].display_text"
                                                                    [attr.data-intro]="ratingList[2].description">
                                                                    {{ratingList[2].display_text}}</th>
                                                                <th class="text-center width_15"
                                                                    [attr.data-title]="ratingList[3].display_text"
                                                                    [attr.data-intro]="ratingList[3].description">
                                                                    {{ratingList[3].display_text}}</th>
                                                                <th class="text-center width_15"
                                                                    [attr.data-title]="ratingList[4].display_text"
                                                                    [attr.data-intro]="ratingList[4].description">
                                                                    {{ratingList[4].display_text}}</th> -->

                                                                <!-- <ng-container *ngFor="let rating of ratingList; let r_i = index;">
                                                                        <th class="text-center width_15 rating_item" [attr.id]="'rating'+sd_i+'_'+r_i" *ngIf="sd_i==0">
                                                                            {{ rating.display_text }}
                                                                        </th>
                                                                        <th class="text-center width_15 rating_item" *ngIf="sd_i>0" [attr.id]="'rating'+sd_i+'_'+r_i">
                                                                            {{ rating.display_text }}
                                                                        </th>
                                                                    </ng-container> -->

                                                            </tr>
                                                            <ng-container
                                                                *ngFor="let question of subdomain.questions; let q_i=index;">
                                                                <tr [attr.id]="'item_'+sd_i+'_'+q_i"
                                                                    class="subdomain_items">
                                                                    <td>
                                                                        <div class="number_mobile">{{q_i+1}}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="label">
                                                                            {{question.competency}}</div>
                                                                    </td>
                                                                    <td>
                                                                        <p class="item_text"
                                                                            [innerHtml]="question.question_text"></p>
                                                                    </td>
                                                                    <!-- <td class="text-center"
                                                                        *ngFor="let rating of ratingList; let r_i=index;">
                                                                        <div class="radio_box">
                                                                            <input type="radio"
                                                                                name="item_answer_{{sd_i}}_{{q_i}}"
                                                                                id="item_{{sd_i}}_{{q_i}}_{{r_i}}"
                                                                                [value]="rating.id"
                                                                                [(ngModel)]="question.rating"
                                                                                (change)="setAnswer(question, subdomain, sd_i)" />
                                                                            <label for="item_{{sd_i}}_{{q_i}}_{{r_i}}">
                                                                            </label>
                                                                        </div>
                                                                    </td> -->
                                                                </tr>

                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                    <!-- <button class="all_btn themeoption_btn float-end margin_domain" (click)="domainChangeFormBtn(1)"  *ngIf="(sd_i+1)==currentDomain.subdomain1.length && domain_index==0">Next Domain</button>
                                                    <button class="all_btn themeoption_btn float-start margin_domain" (click)="domainChangeFormBtn(0)" *ngIf="sd_i==0 && currentDomain.subdomain1.length==11 && domain_index==1">Previous Domain</button> -->
                                                </div>
                                                <!-- </ng-container> -->

                                                <!-- <button class="all_btn themeoption_btn float-end margin_domain" *ngIf="(sd_i+1)==currentDomain.subdomain1.length">Next Domain</button>
                                                <button class="all_btn themeoption_btn float-start margin_domain" *ngIf="sd_i==0">Previous Domain</button> -->
                                            </ng-container>
                                        </div>
                                        <div class="text-center">

                                            <button *ngIf="getNextDomainButton()"
                                                class="all_btn theme_btn float-end margin_domain" id="next_btn1"
                                                (click)="domainChangeFormBtn(domain_index + 1)">Next Domain</button>
                                            <button *ngIf="getPreviousDomainButton()" 
                                             class="all_btn themeoption_btn float-start margin_domain"
                                                id="prev_btn" (click)="domainChangeFormBtn(domain_index - 1)">Previous
                                                Domain</button>
                                                
                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="assdomain2" role="tabpanel"
                                    aria-labelledby="assdomain2-tab">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end - items box -->
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="QuestionModal" tabindex="-1" role="dialog" aria-labelledby="QuestionModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title float-start" id="QuestionModalLongTitle">IFMA’s self-assessment Survey</h5>
                <button type="button" class="close float-end" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="clearfix"></div>
                <p class="pt-3">Thank you for taking this survey. Please help us by sharing your feedback on the
                    self-assessment.</p>
            </div>
            <div class="modal-body" *ngIf="SurveyQuestions.length>0">
                <kendo-formfield>
                    <label>{{SurveyQuestions[0].question_text}}</label>
                    <kendo-multiselect [data]="surveyQuestionOptions" [(ngModel)]="SurveyQuestions[0].answer_text"
                        (valueChange)="saveDefaultQuestion(0)" [autoClose]="false">
                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                            <div class="check_box">
                                <input type="checkbox" class="k-checkbox"
                                    [checked]="isSurveyQuestionOptionsSelected(dataItem)">
                                <label class="k-checkbox-label">{{dataItem}}</label>
                            </div>
                        </ng-template>
                    </kendo-multiselect>
                </kendo-formfield>
                <kendo-formfield>
                    <label>{{SurveyQuestions[1].question_text}}</label>
                    <textarea kendoTextArea #answer_text="ngModel" [(ngModel)]="SurveyQuestions[1].answer_text"
                        (change)="saveDefaultQuestion(1)"></textarea>
                </kendo-formfield>
                <kendo-formfield>
                    <label>{{SurveyQuestions[2].question_text}}</label>
                    <div class="rate_stars">

                        <button *ngIf="SurveyQuestions[2].answer_text"
                            (click)="SurveyQuestions[2].answer_text = null; saveDefaultQuestion(2)"
                            class="float-end clear_btn">
                            <i class="fa fa-times"></i>Clear</button>
                        <p *ngIf="SurveyQuestions[2].answer_text == 0" class="float-end inline_text">Very Poorly</p>
                        <p *ngIf="SurveyQuestions[2].answer_text == 1" class="float-end inline_text">Poorly</p>
                        <p *ngIf="SurveyQuestions[2].answer_text == 2" class="float-end inline_text">Adequately</p>
                        <p *ngIf="SurveyQuestions[2].answer_text == 3" class="float-end inline_text">Well</p>
                        <p *ngIf="SurveyQuestions[2].answer_text == 4" class="float-end inline_text">Very Well</p>


                        <input type="radio" id="rating4" value="4" #answer_text="ngModel"
                            [(ngModel)]="SurveyQuestions[2].answer_text" (change)="saveDefaultQuestion(2)" />
                        <label for="rating4" title="Very Well" kendoTooltip></label>

                        <input type="radio" id="rating3" value="3" #answer_text="ngModel"
                            [(ngModel)]="SurveyQuestions[2].answer_text" (change)="saveDefaultQuestion(2)" />
                        <label for="rating3" title="Well" kendoTooltip></label>

                        <input type="radio" id="rating2" value="2" #answer_text="ngModel"
                            [(ngModel)]="SurveyQuestions[2].answer_text" (change)="saveDefaultQuestion(2)" />
                        <label for="rating2" title="Adequately" kendoTooltip></label>

                        <input type="radio" id="rating1" value="1" #answer_text="ngModel"
                            [(ngModel)]="SurveyQuestions[2].answer_text" (change)="saveDefaultQuestion(2)" />
                        <label for="rating1" title="Poorly" kendoTooltip></label>

                        <input type="radio" id="rating0" value="0" #answer_text="ngModel"
                            [(ngModel)]="SurveyQuestions[2].answer_text" (change)="saveDefaultQuestion(2)" />
                        <label for="rating0" title="Very Poorly" kendoTooltip></label>
                    </div>
                </kendo-formfield>
            </div>
            <div class="modal-footer">
                <p>Are you sure you want to submit this survey?</p>
                <button type="button" (click)="submitFinal()" class="all_btn theme_btn">Yes</button>
                <button type="button" data-bs-dismiss="modal" class="all_btn themeoption_btn">No</button>
                <!-- <button type="button" (click)="submitFinal()" class="all_btn theme_btn">Skip and Submit</button> -->
            </div>
        </div>
    </div>
</div>