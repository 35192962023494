<header class="auth_header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
        <div class="logo_block"><a routerLink="/" class="logo_a auth_logo_hide"><img src="{{globals.cndURL}}assets/images/logo-email.png" alt=""
          class="img-fluid" /></a><span><span class="align-top d-inline me-3">Products</span>
             <span class="qa_testing_block" *ngIf="globals.TestMode">Beta Version</span>
          </span></div>
      </div>
      <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 textcenter_mobile">
        <div class="user_dropdown" *ngIf="globals.authData!=undefined">
          
          <!-- <a href="https://www.ifma.org/" target="_blank" class="backwebsite_btn mr-4 d-none d-lg-inline-block">Visit AMSN Website</a> -->
          <div class="header_dropdown dropdown">
            <!-- <button class="dropdown-toggle user_button" id="profiledb" (click)="dropdown_open()"> -->
              <button class="dropdown-toggle user_button" id="profiledb">
              <i class="fa fa-user icon_user"></i>
              <!--<img src="{{globals.cndURL}}assets/images/user.jpg" alt="" class="img-fluid" /> -->
              <span class="name"><strong>{{globals.authData?.first_name}}
                {{globals.authData?.last_name}} </strong><span class="id_box mb-0 ms-2">{{globals.authData?.amsn_user_id}}</span>
                <i *ngIf="globals?.institute_details" class="role_margin"><b>Current Role:</b>{{globals.institute_details?.role_name}}</i>
              </span>

            </button>
           
          </div>
          <button kendoButton (click)="logout()" class="logout_btn">
            <i class="fa fa-sign-out"></i>Logout
          </button>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  
</header>